/*================================================================================
	Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
	Version: 2.0
	Author: PIXINVENT
	Author URL: http://www.themeforest.net/user/pixinvent
================================================================================

NOTE:
------
PLACE HERE YOUR OWN SCSS CODES AND IF NEEDED, OVERRIDE THE STYLES FROM THE OTHER STYLESHEETS.
WE WILL RELEASE FUTURE UPDATES SO IN ORDER TO NOT OVERWRITE YOUR STYLES IT'S BETTER LIKE THIS.  */

.single-project-header {
  .card-action {
    margin-bottom: 0.85rem;
    background-color: #3773ff;
  }
  h4.card-title {
    text-transform: capitalize;
    font-size: 20px !important;
    width: 100%;
  }

  .header-subtitle {
    display: block;
    font-weight: 600;
    padding-bottom: 0.5rem;
  }
  .subtitle-content {
    line-height: 15px;
    font-size: 12px;
  }
  .sub-data {
    margin-top: 5px;
    span {
      // min-width:30%;
      // background-color: yellowgreen;
      padding-right: 0.5rem;
      font-size: 12px;
    }
  }
}

.projectTabs {
  background-color: #ffffff;
  box-shadow: 0 4px 24px 0 rgb(34 41 47 / 10%);
  display: flex;
  justify-content: center;
  li:nth-child(1) .tab-title {
    margin-left: 0;
  }
  .tab-title {
    padding: 0.68rem 1.5rem;
    margin: 0.74rem 0 0.74rem 1.5rem;
    background-color: #f8f8f8;
    border: 0px;
  }
}
.decision-card {
  .card-body {
    .card-open-btn {
      background-color: #e4fdf6;
      color: rgb(2, 158, 67);
      font-weight: 600;
      border: none;
      font-size: 12px;
      padding: 0 1rem 0 1rem;
      margin-bottom: 1rem;
      border-radius: 1rem;
    }
  }
  .card-header {
    display: flex;
    // background-color: #9032ED;
    color: #ffffff;
    h4 {
      color: #ffffff;
      font-weight: 600;
      font-size: 1.2rem;
    }
    margin-bottom: 1rem;
  }
  .card-footer {
    display: flex;
    justify-content: space-between;
    border-top: none;
    .card-footer-btn {
      border: none;
      padding: 0 0.7rem 0.3rem 0.7rem;
      font-size: 25px;
      background-color: #004dff;
      color: #ffff;
      border-radius: 0.4rem;
    }
    .card-footer-data {
      font-size: 14px;
    }
  }
}
.decision-card {
  .card-header {
    padding: 1rem 1.2rem;
  }
  .card-body-content-desc {
    * {
      font-size: 13px !important;
      line-height: 22px;
      overflow: hidden;
      height: 44px;
      background: none !important;
    }
    :last-child {
      margin: 0;
    }
  }
}
.decisionsList {
  .card-header {
    border-radius: 4px;
    h6 {
      text-transform: uppercase;
      color: #ffffff;
      font-size: 15px;
    }
    * {
      color: #ffffff;
      margin: 0;
    }
  }
  .card-title {
    font-size: 16px;
  }
  .desc {
    width: 48%;
    * {
      font-size: 12px !important;
      color: #ffffff !important;
      margin: 0;
      text-align: left !important;
      line-height: 20px;
      overflow: hidden;
      max-height: 40px;
      background: none !important;
    }
    :not(:first-child) {
      display: none;
    }
  }
  .btn-light * {
    color: #004dff !important;
  }
}
/*.card-body-content-desc{
	display: flex;
	font-size: 12px;
	width: 200px;
	height: 20px;
	overflow: hidden;
}*/
.task-card {
  .card-body {
    .card-open-btn {
      background-color: #e4fdf6;
      color: rgb(2, 158, 67);
      font-weight: 600;
      border: none;
      font-size: 12px;
      padding: 0 1rem 0 1rem;
      margin-bottom: 1rem;
      border-radius: 1rem;
    }
    .card-body-content {
      div {
        * {
          font-size: 13px !important;
          line-height: 22px;
          overflow: hidden;
          height: 88px;
          background: none !important;
        }
      }
      .desc {
        :not(:first-child) {
          display: none;
        }
      }
    }
    .task-sub-data {
      margin-top: 1rem;
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      span {
        margin-top: 5px;
      }
      // span:nth-child(2){ text-align: right; }
    }
  }
  .card-header-content {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    padding: 1.5rem 1.5rem 0 1.5rem;
    .header-one {
      width: 90%;
      + div {
        width: 10%;
      }
      span {
        color: #b9b9c3;
        font-size: 11px;
      }
    }
    h4 {
      display: block;
      margin: 0;
      a {
        color: #6e6b7b;
        :hover {
          color: #0048ff;
        }
      }
    }
  }
  .card-footer-content {
    display: flex;
    justify-content: flex-end;
    border: none;
    padding: 0 1rem 1rem 0;
    .card-footer-btn {
      border: none;
      padding: 0 0.7rem 0.3rem 0.7rem;
      font-size: 25px;
      background-color: #004dff;
      color: #ffff;
      border-radius: 0.4rem;
    }
    .card-footer-data {
      font-size: 14px;
    }
  }
}
#user_role_style {
  color: rgb(11, 11, 240);
}
.task-title-sty {
  width: 100%;
  line-height: 1.4rem;
  height: 1.4rem;
  overflow: hidden;
  text-transform: capitalize;
  font-weight: 600;
  font-size: 1.2rem;
}
.task-title-sty:hover {
  opacity: 0.6;
}
.card-body-content-list {
  height: 20px;
  overflow: hidden;
  font-size: 13px;
}
.notes-card {
  .card-body {
    .card-open-btn {
      background-color: #e4fdf6;
      color: rgb(2, 158, 67);
      font-weight: 600;
      border: none;
      font-size: 12px;
      padding: 0 1rem 0 1rem;
      margin-bottom: 1rem;
      border-radius: 1rem;
    }
    .card-body-content {
      display: flex;
      font-size: 11px;
    }
  }
  .card-header-content {
    display: flex;
    justify-content: space-between;
    .header-one {
      display: flex;
      flex-direction: column;
      span {
        color: #b9b9c3;
        font-size: 11px;
      }
    }

    padding: 1.5rem 1.5rem 0 1.5rem;
    h4 {
      display: block;
    }
  }
}
.todo-card {
  .card-body {
    .card-open-btn {
      background-color: #e4fdf6;
      color: rgb(2, 158, 67);
      font-weight: 600;
      border: none;
      font-size: 12px;
      padding: 0 1rem 0 1rem;
      margin-bottom: 1rem;
      border-radius: 1rem;
    }
    .card-body-content {
      // padding-top: 2rem;
      // margin-left: 1rem;
      // display: flex;
      // flex-direction: column;
      // font-size: 10px;
      // color: #B9B9C3;
      // min-width: 200px;
      min-height: 150px;

      .todo-sub-content {
        padding-left: 0.5rem;
      }
      .form-group {
        display: flex;
        justify-content: space-between;
      }
      span.edit-title-todo {
        margin-right: 5px;
      }
      // span.edit-title-todo, span.delete-title-todo{

      // }
    }
  }
  .todo-btn-area {
    padding-bottom: 1rem;
    display: flex;
    justify-content: center;
  }
  .progress-bar {
    background-color: #0048ff;
  }
  .todo-footer-btn {
    border: none;
    background-color: #0048ff;
    color: #ffff;
    font-size: 20px;
    border-radius: 50%;
    /* margin: 0.5rem; */
    padding: 0 0.4rem 0.2rem 0.5rem;
  }
  .card-header-content {
    display: flex;
    justify-content: space-between;
    .header-one {
      display: flex;
      flex-direction: column;
      span {
        color: #b9b9c3;
        font-size: 11px;
      }
    }

    padding: 1.5rem 1.5rem 0 1.5rem;
    h4 {
      display: block;
    }
  }
}
.subtask-addnew-btn {
  border: none;
  background-color: #004dff !important;
  color: #ffff;
  border-radius: 0.4rem;
  padding: 0.6rem 1rem;
  font-size: 0.98rem;
  margin: -88px 200px 0 240px;
}

/*a.card-addnew-btn.btn.btn-secondary {
    border: none;
    background-color: #004DFF !important;
    color: #ffff;
    border-radius: 0.4rem;
	padding: 0.586rem 1.5rem;
	font-size: 0.98rem;
}*/
a.card-addnew-btn.btn.btn-secondary:hover {
  box-shadow: 0 2px 18px 0 #004dff;
}
.nav-pills .nav-link.active {
  border-color: #004dff !important;
  box-shadow: 0 4px 15px -4px #dde3f3;
}

.Calender-color2 {
  color: #ff4b4b;
}
.Calender-color1,
.Calender-color2 {
  max-width: auto;
  max-height: 15px;
}
.card-footer-dataa {
  padding-top: 20px;
  font-size: 14px;
}
.arrow-button {
  // background-color: #9032ED;
  text-align: center;
  margin-top: 5px;
}
.card-footer-btnn {
  border: none;
  padding: 0 0.7rem 0.3rem 0.7rem;
  font-size: 25px;
  background-color: #004dff !important;
  color: #ffff;
  border-radius: 0.4rem;
}
.card-footer {
  display: flex;
  justify-content: space-between;
  border-top: none;
}
.decision-calander {
  max-height: 20px;
  max-width: auto;
  padding-bottom: 5px;
}
.task-calander {
  max-height: 20px;
  max-width: auto;
  padding-bottom: 5px;
  padding-right: 8px;
}
.decision-button {
  max-width: auto;
  max-height: 40px;
}
* {
  box-sizing: border-box;
}

body {
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

input,
textarea {
  outline: none;
  resize: none;
}

.notes-list {
  display: flex;
  flex-wrap: wrap;
  padding: 0px;
}

.app-header__title {
  margin: 20px 0px;
}

.app-header__controls {
  display: flex;
  align-items: center;
  margin: 10px 0 20px 0;
}

.add-new {
  min-width: 180px;
}

.search {
  flex-grow: 1;
}
.react-grid-layout {
  width: 100%;
  margin-left: -10px;
  margin-right: -10px;
} 
.drag-comp-pointer{
  cursor: pointer;
}
.draggable-note {
  text-decoration: none;
  color: #000;
  background: #8a7ff5;
  display: block;
  height: auto;
  //   width:100%;
  padding: 1.2rem 2rem;
  border-radius: 3px;
  overflow: hidden;
  //   transform: rotate(-3deg);
  //   transition: transform .15s linear;
  //   margin: 10px;
}
.note__title,
.note__description {
  width: 100%;
  padding: 6px 14px;
  background: transparent;
  color: #ffffff;
  border-color: transparent;
  border: 1px solid;
}
.note__title{
  border:none !important
}
.note__description::-webkit-scrollbar {
  width: 5px;
}
.note__description::-webkit-scrollbar-thumb {
  background: rgba(255, 255, 255, 0.5);
}
.note__description::-webkit-scrollbar-thumb:hover {
  background: rgba(255, 255, 255, 0.9);
}
.note__title {
  height: 15%;
  margin-bottom: 4%;
}
.note__description {
  height: 81%;
}
.note__title::placeholder,
.note__description::placeholder {
  color: #e9d7d7;
  font-size: large;
}
.note__title {
  font-weight: 600;
  font-size: 1.14rem;
}
.note__description {
  font-size: 0.84rem;
  display: block;
}

.note__delete {
  position: absolute;
  top: 4px;
  right: 8px;
  font-size: 18px;
  color: #ffffff;
}

.note__delete:hover {
  color: rgb(207, 198, 198);
  cursor: pointer;
}

.nav-tabs-1 {
  background: #ffffff;
  box-shadow: 0px 4px 16px rgba(35, 31, 72, 0.04);
  border-radius: 6px;
  padding: 20px 20px 20px 20px;
  align-items: center;
}
.nav-items {
  background: #f8f8f8;
  border-radius: 5px;
  padding: 0 10px 0 10px;
  margin: 5px;
  color: #7367f0;
}
.nav-items:hover,
.nav-links.nav-link.active {
  color: #ffffff;
  background: #7367f0;
  box-shadow: 0px 0px 6px rgba(115, 103, 240, 0.6);
  border-radius: 5px;
}
.App {
  width: 100%;
  margin: 0 auto;
}

.drag-container {
  text-align: center;
}

.head {
  display: inline-block;
  margin: 0;
  padding: 0;
  background-color: #cccccc;
  width: 100%;
}
.droppable {
  position: absolute;
  width: 200px;
  height: 200px;
  right: 0;
  top: 10;
  background-color: #9e9e9e;
  border-left: 2px dotted red;
}
.inProgress {
  position: absolute;
  width: 200px;
  height: 200px;
  left: 0;
  top: 10;
  background-color: #eeeeee;
  border-right: 2px dotted red;
}
.text-nowrap {
  max-height: 38px;
  max-width: auto;
  margin-top: 9px;
}
a.single-task-tab.nav-link.active {
  background: #6969696b !important;
  color: #ffffff;
  font-weight: 600;
  box-shadow: 0 2px 18px 0 #6969692e;
}
a.single-task-tab.nav-link {
  border: 1px solid #6969696b;
  color: #6969696b;
}
.nav-pills .nav-link.active {
  border-color: #6969696b !important;
  box-shadow: 0 4px 15px -4px #dde3f3;
}

.single-information {
  border-radius: 6px 0 0 6px !important;
}
.single-task {
  border-radius: 0 6px 6px 0 !important;
}
a.card-addnew-btn.task-addsubtask.btn.btn-secondary {
  background: #d6e1fd !important;
  color: #004dff !important;
}
.project-open-status {
  pointer-events: none;
  padding: 0.6rem;
  font-size: 11px;
  margin: 0 0 -50px 90px;
  color: #ffffff;
}
.dropdwon-button {
  text-align: right;
}
.arrow-button-list {
  text-align: center;
  margin: 2px -3px 0 -3px;
}
.card-footer-btnn-list {
  border: none;
  padding: 0 0.7rem 0.3rem 0.7rem;
  background-color: #004dff !important;
  color: #ffff;
  border-radius: 0.4rem;
  margin: -3px 0 0 110px;
}
.card-footer-content-list {
  display: flex;
  justify-content: flex-end;
  border: none;
  padding: 0 1rem 1rem 0;
}
.card-header-content-list {
  margin: 10px 0 0 10px;
  font-size: xx-small;
}
.task-sub-data-list1,
.task-sub-data-list2 {
  margin-top: 20px;
}
.show-error-msg {
  color: red;
}
.task-card-list {
  height: 60px;
}
.show-error-msg {
  color: red;
}
// task tab list css
.task-tab-list {
  // box-shadow: inset 5px 1px 0px 0px rgb(34 41 47 / 10%);
  margin-bottom: 1rem;
  .taskHeader.card-header {
    padding: 1rem;
    h4.task-title {
      text-transform: capitalize;
      font-size: 1.2rem;
      line-height: 18px;
      height: 18px;
      font-weight: 600;
      overflow: hidden;
      min-width: 200px;
      max-width: 200px;
      a {
        color: #6e6b7b;
        :hover {
          color: #0048ff;
        }
      }
    }
    .title-element {
      display: flex;
      width: 92%;;
      align-items: center;
      .task-date{
        // background-color: #0048ff;
        display:flex;
        //justify-content: space-between;
        align-items: center;
        min-width: 35%;
      }
      .title-t-title,
      .font-weight-bolder,
      .sub-task-count-list,
      .card-data1-list,
      .card-data2-list {
        width: 50%;
        float: left;
      }
    }
    .task-open-btn {
      pointer-events: none;
      padding: 8px 15px;
      border-radius: 3px;
	  margin-top:5px;
	  margin-bottom:5px;
	//   margin-left:90px;
    }
  }
}
.card-data1 span,
.card-data2 span {
  font-size: x-small;
  margin-left: 5px;
}
// project collaps
.dark-layout .sub-collaps-header {
  background: none;
}

.single-project-header {
  * {
    color: #ffffff;
  }
  .card-header {
    padding: 1.15rem;
  }
  .sub-collaps-header {
    h6 {
      margin: 0;
      cursor: pointer;
    }
  }
  .sub-collapse {
    .card {
      margin: 0;
      background: none;
    }
    .card-body {
      :last-child {
        margin: 0;
      }
    }
  }
}

.back-button {
  border: none;
  color: #004dff !important;
  color: #ffff;
  border-radius: 0.4rem;
  // padding: 0.586rem 1.5rem;
  margin: 1rem;
  font-size: 0.98rem;
  margin-right: 8px;
  // padding: 0.6rem 2.8rem 0.6rem 2.8rem;
  // padding: 0.6rem 0.8rem 0.6rem 0.8rem;
}
body > iframe {
  display: none;
}
.projectCard::before {
  content: "";
  display: inline-block;
  width: 15px;
  height: 15px;
  position: absolute;
  left: 0;
  top: 0;
  background-color: #00ef6b;
}
.projectGirdCard::after {
  content: "";
  display: inline-block;
  width: 15px;
  height: 15px;
  position: absolute;
  right: 0;
  bottom: 0;
  background-color: #00ef6b;
}
.projectCard .items-count {
  flex-wrap: wrap;
}
.projectListCard .items-count {
  width: 74%;
}
.projectListCard .title-sec {
  width: 21%;
}
.projectListCard .items-count .badge {
  width: 18%;
}
.projectCard .items-count .badge {
  padding: 10px 15px;
  // display: inline-block;
  font-weight: 500;
  border-radius: 3px;
}
.projectGirdCard {
  .card-header {
    padding-bottom: 1.15rem;
  }
  .title-project-sty {
    min-width: 90%;
    max-width: 90%;
    font-weight: 600;
  }
  .items-count {
    .badge:nth-child(1),
    .badge:nth-child(2) {
      width: 48%;
      margin-bottom: 10px;
    }
    .badge:nth-child(3),
    .badge:nth-child(4),
    .badge:nth-child(5) {
      width: 31.33%;
    }
  }
}
.project-desc {
  height: 48px;
  overflow: hidden;
}
.title-project-sty {
  max-width: 210px;
  overflow: hidden;
  font-size: 1rem;
  line-height: 1.35rem;
  height: 1.35rem;
  font-weight: 600 !important;
  color: #5e5873;
}

.card-back-btn:hover {
  box-shadow: 0 2px 18px 0 #004dff;
}
::-webkit-scrollbar-track {
  // box-shadow: inset 0 0 3px #ccc;
  border-radius: 0px;
}
::-webkit-scrollbar-thumb {
  background: #a1a1a1 !important;
  border-radius: 3px;
}
::-webkit-scrollbar {
  width: 7px;
  height: 10px !important;
}
::-webkit-scrollbar-thumb:hover {
  background: #858181;
}
.Select-menu-outer {
  z-index: 999 !important;
}
.Select.is-open {
  position: relative;
  z-index: 1000;
}
:global(.Select-menu-outer) {
  z-index: 999 !important;
}

/**Remove Editor zindex*/
.rdw-text-align-dropdown {
  z-index: 0 !important;
}
.rdw-list-dropdown {
  z-index: 0 !important;
}
/**Remove Editor zindex*/

.card-employee-task-list-body {
  padding: 0 1.5rem 1.5rem 1.5rem;
}
.sub-tasks-desc-log {
  padding-top: 1.5rem;
}
.sub-tasks-title {
  color: #1f63ff;
}
.avatar-message {
  color: rgb(240, 16, 16);
  margin-left: 25px;
}
.desc-title,
.desc-name {
  width: 200px;
  height: 20px;
  overflow: hidden;
  a {
    color: #fff !important;
  }
  a:hover {
    opacity: 0.9 !important;
  }
}
.card-back-btn {
  margin: -8px 0 15px -10px;
  text-align: center;
  font-size: 15px;
}
.card-back-btn:hover {
  box-shadow: 0 2px 18px 0 #004dff;
}
.Select-menu-outer {
  z-index: 999 !important;
}
.Select.is-open {
  position: relative;
  z-index: 1000;
}
:global(.Select-menu-outer) {
  z-index: 999 !important;
}

/**Remove Editor zindex*/
.rdw-text-align-dropdown {
  z-index: 0 !important;
}
.rdw-list-dropdown {
  z-index: 0 !important;
}
/**Remove Editor zindex*/

.card-employee-task-list-body {
  padding: 0 1.5rem 1.5rem 1.5rem;
}
.sub-tasks-desc-log {
  padding-top: 1.5rem;
}
.sub-tasks-title {
  color: #1f63ff;
}
.avatar-message {
  color: rgb(240, 16, 16);
  margin-left: 25px;
}
//issues page
.issueCard {
  .issue-title {
    line-height: 1.4rem;
    height: 1.4rem;
    overflow: hidden;
    font-weight: 600;
    color: #ffffff;
    font-size: 1.2rem;
    a {
      color: #ffffff !important;
    }
  }
  .issue-header {
    color: #ffffff;
    padding: 1rem 1.2rem;
    .issue-text,
    .issue-cursor-pointer {
      color: #ffffff;
    }
    .issue-text {
      font-size: 0.8rem;
      line-height: 1rem;
      height: 1rem;
      overflow: hidden;
    }
  }
}
.issueGrid {
  .issue-header {
    .titleSec {
      width: 90%;
    }
    .iconSec {
      width: 10%;
    }
  }
  .issue-date {
    flex-wrap: wrap;
    .card-date1 {
      font-size: 0.8rem;
      margin-top: 5px;
    }
    .card-date2 {
      margin-top: 5px;
      font-size: 0.8rem;
    }
    .Calender-color2 {
      color: #ff4b4b;
    }
  }
}
.sort-issue {
  margin: 25px 0 25px -12px;
  .react-select-issue {
    margin: -10px 0 10px -20px;
  }
}
.issue-desc {
  margin: 10px 0 15px 0;
  font-size: 13px;
  line-height: 18px;
  height: 36px;
  overflow: hidden;
}
//issue list
.issueList {
  margin-bottom: 1rem;
  .Calender-color2 {
    color: #ffffff;
  }
  .titleSec {
    width: 24%;
  }
  .Calender-color1,
  .Calender-color2 {
    max-height: 100%;
  }
}
.card-spinner {
  position: relative;
  .fallback-spinner {
    height: 100%;
    width: 100%;
    .component-loader {
      margin-top: 0;
      transform: translateY(-50%);
    }
  }
}

.font-weight-700 {
  font-weight: 700 !important;
}
.font-weight-600 {
  font-weight: 600 !important;
}
.font-weight-500 {
  font-weight: 500 !important;
}
//avatar
// .user-nav-avatar{
// 	color: #1f63ff;
// 	display: flex;
// 	margin-top: 400px;
// 	margin-left: 50px;
// 	.avatar-text{
// 		margin: 10px 0 10px 10px;
// 	}
// }

.gantt-chart .grid .gridBody .rows rect:nth-child(even),
.gantt-chart-user .grid .gridBody .rows rect:nth-child(even) {
  fill: #fff;
}

.gantt-h5 {
  margin: 0;
  margin-bottom: 3px;
  width: 100%;
  max-width: 80%;
  overflow: hidden;
  line-height: 18px;
  height: 18px;
}

.gantt-span {
  padding: 3px 8px;
  color: #fff;
  border-bottom-left-radius: 5px;
  border-top-left-radius: 5px;
  position: absolute;
  right: 0;
  top: 8px;
}

.gantt-p {
  margin: 0;
  font-size: 12px;
  color: #bbb;
}

.font-size-13 {
  font-size: 13px;
}

.user-dropdown-navbar,
.menu-expanded .user-dropdown-navbar_collapsed {
  flex-direction: row;
  padding: 9px 14px !important;
  margin: 8px auto !important;
  max-width: 90%;
  box-shadow: 0 0 24px rgb(24 41 47 / 12%);
  border-radius: 6px;
  .dropdown-user a,
  .dropdown-notification a {
    padding: 0 !important;
  }
}
// .menu-collapsed .user-dropdown-navbar {
.user-dropdown-navbar_collapsed {
  flex-direction: column;
  padding: 15px 14px 9px 14px !important;
  margin: 8px auto !important;
  max-width: 90%;
  box-shadow: 0 0 24px rgb(24 41 47 / 12%);
  border-radius: 6px;
  .dropdown-notification .badge {
    top: -8px;
  }
}
.text-black {
  color: #000;
}

button.waves-effect.mr-1.btn.btn-primary.btn-sm {
  background: #4f5252 !important;
  border: 1 solid #4f5252 !important;
}

.bookmark-wrapper .screen-mode {
  // background: #ffffff;
  // padding: 9px 35px !important;
  // margin: 10px 0 0 10px auto !important;
  // box-shadow: 0 0 24px rgb(24 41 47 / 12%);
  // border-radius: 6px;

  span.item-name-side {
    margin-right: 1rem;
  }
  svg.ficon {
    margin-left: 3rem;
  }
}

// .vertical-layout .content.app-content {
// 	padding-top: 2rem;
// }

.layout-navbar {
  padding: 9px 14px !important;
  margin: 8px auto !important;
  box-shadow: 0 0 24px rgb(24 41 47 / 12%);
  border-radius: 6px;
  flex-direction: row;
  max-width: 90%;
}

.decision-edit-form .DraftEditor-editorContainer {
  z-index: 0;
}

.comment-card {
  background-color: rgba(115, 103, 240, 0.1);
  // *{
  //   color: #fff !important;
  // }
}
.comments-btn {
  border: 1px solid #004dff;
  background-color: transparent;
  color: #004dff;
}
.comments-btn:hover {
  color: #004dff !important;
}
.task-suggestion-content {
  color: #323434;
}

// .comment-card *{
// 	color: #fff !important;
// }
// .app-content {
// 	min-height: 68vh;
// }
.todo-card-body {
  height: 250px;
  overflow-y: auto;
}

//attendence
td.attend-date,
table,
td,
th {
  padding: 0 !important;
}
td.attend-date,
td.attend-name,
td.td-username,
td.attend-total,
td.entryColmCount {
  background-color: rgba(55, 115, 255, 0.3);
  color: #3773ff;
  text-align: center;
}
td.entryColm {
  background-color: rgba(55, 115, 255, 0.2);
}
.attendence-header {
  background-color: rgba(55, 115, 255, 0.2);
}
.kpi-id,
.kpi-name,
.attend-title {
  color: #3773ff;
}
.attend-search {
  margin: 10px 10px 0 0;
}
input.actual {
  height: 100%;
  width: 100%;
}
input.actual:checked ~ .checkmark {
  background-color: red;
}
.actual:before {
  background-color: red;
}
.custom-checkbox .actual:checked ~ .actual::before {
  background-color: black;
}
label.attendance-label {
  font-size: 14px;
  position: relative;
  color: #3773ff;
  font-style: normal;
  font-weight: 600;
  z-index: 3;
}
.select-box-input {
  z-index: 5;
  border-radius: 20px;
  .select__value-container,
  .select__indicator,
  .select__indicators,
  .select__control {
    border-radius: 20px;
    color: #3773ff;
  }
}
input.actual {
  z-index: 3;
  opacity: 0;
  top: 0;
  left: 0;
  position: absolute;
  width: 100%;
}
input[type="checkbox"]:checked.actual {
  opacity: 0;
  padding: 0;
}
.attend-table td.td-username {
  text-transform: capitalize;
  font-size: 12px;
  padding: 7px !important;
  cursor: pointer;
}
.entryColmCount {
  font-size: 13px;
}
.attend-table .entryColm,
.attend-table .entryColmCount {
  height: 20px;
  width: 20px;
  border-right: white 1px solid;
  text-align: center;
  position: relative;
}
td.attend-name {
  height: 10px;
  width: 10px;
  padding: 0;
}

//client
.chart-container {
  display: flex;
  width: 100%;
  height: 100%;
}

.gantt-chart-user .content .bar text {
  display: none !important;
}

.menu-collapsed .footer {
  // position: 'absolute';
  // bottom: '0';
  // right: '0';
  width: calc(100% - 80px);
}

.menu-expanded .footer {
  width: calc(100% - 260px);
}

.selected-row {
  background-color: #bbb;
}
//client select
span.css-168wdl6-indicatorSeparator {
  -webkit-align-self: stretch;
  -ms-flex-item-align: stretch;
  align-self: stretch;
  background-color: #ededed;
  margin-bottom: 8px;
  margin-top: 8px;
  width: 0px;
  box-sizing: border-box;
}
//milestone

.checkbox-milestone-style {
  width: 20px;
  height: 20px;
}
.checkbox-milestone-label {
  font-size: 18px;
  margin-left: 8px;
}
span.vertical-timeline-element-date {
  color: #625f6e;
  font-weight: 600;
  font-size: 18px;
}
.vertical-timeline.vertical-timeline--two-columns:before {
  left: 50%;
  margin-left: -2px;
  background-color: #adadad;
}
span.css-1okebmr-indicatorSeparator {
  width: 0px !important;
}

//techniques
.temp-card-header-icon {
  margin-right: 5px;
}
.temp-card-body {
  height: 200px;
  background: linear-gradient(118deg, #004dff, rgba(66, 255, 0, 1));
  margin: auto;
}
.temp-card-footer-name {
  margin: auto;
  font-weight: 600;
}
.temp-card-footer {
  display: inline;
  justify-content: center;
  text-align: center;
}
// 5whys
// h4.five-why-problem{
// 	margin-left: 20px;
//     margin-top: 20px;
//     border: 1px solid;
//     padding: 25px;
//     border-radius: 50px 100px;
//     background-color: #FA5039;
//     color: #fff;
// }
.container-five-problem {
  textarea.five-why-problem.form-control {
    width: 24rem;
    border: 0;
    background-color: rgb(250, 80, 57);
    color: #fff;
    padding: 35px;
    border-radius: 50px 100px;
    margin-top: 20px;
    margin-left: 20px;
  }
}
.container-why-one {
  margin-left: 150px;
  margin-top: 50px;
  h4.why-one {
    display: inline-block;
    padding: 15px 30px;
    background-color: #0ece7d;
    border-radius: 25px 50px;
    /* margin-top: 40px; */
    margin-left: -97px;
    color: #fff;
    z-index: 7 !important;
  }
  textarea.form-why-one.form-control {
    width: 22rem;
    border: 0;
    background-color: rgba(14, 206, 126, 0.842);
    color: #fff;
    padding: 35px;
    border-radius: 50px 100px;
    margin-top: -69px;
    z-index: 7 !important;
  }
}
.container-why-two {
  margin-left: 250px;
  margin-top: 50px;
  textarea.form-why-two.form-control {
    width: 22rem;
    border: 0;
    background-color: rgba(242, 245, 56, 0.9);
    color: #fff;
    padding: 35px;
    border-radius: 50px 100px;
    margin-top: -69px;
  }
  h4.why-two {
    display: inline-block;
    padding: 15px 30px;
    background-color: #ebef3d;
    border-radius: 25px 50px;
    /* margin-top: 40px; */
    margin-left: -97px;
    color: #fff;
  }
}
.container-why-three {
  margin-left: 350px;
  margin-top: 50px;
  textarea.form-why-three.form-control {
    width: 22rem;
    border: 0;
    background-color: rgba(54, 210, 220, 0.9);
    color: #fff;
    padding: 35px;
    border-radius: 50px 100px;
    margin-top: -69px;
  }
  h4.why-three {
    display: inline-block;
    padding: 15px 30px;
    background-color: #36d2dc;
    border-radius: 25px 50px;
    /* margin-top: 40px; */
    margin-left: -97px;
    color: #fff;
  }
}
.container-why-four {
  margin-left: 450px;
  margin-top: 50px;
  textarea.form-why-four.form-control {
    width: 22rem;
    border: 0;
    background-color: rgba(230, 113, 233, 0.9);
    color: #fff;
    padding: 35px;
    border-radius: 50px 100px;
    margin-top: -69px;
  }
  h4.why-four {
    display: inline-block;
    padding: 15px 30px;
    background-color: #e671e9;
    border-radius: 25px 50px;
    /* margin-top: 40px; */
    margin-left: -97px;
    color: #fff;
  }
}
.container-why-five {
  margin-left: 550px;
  margin-top: 50px;
  textarea.form-why-five.form-control {
    width: 22rem;
    border: 0;
    background-color: rgba(253, 162, 26, 0.9);
    color: #fff;
    padding: 35px;
    border-radius: 50px 100px;
    margin-top: -69px;
  }
  h4.why-five {
    display: inline-block;
    padding: 15px 30px;
    background-color: #fda21a;
    border-radius: 25px 50px;
    /* margin-top: 40px; */
    margin-left: -97px;
    color: #fff;
  }
}
.container-five-cause {
  textarea.five-why-cause.form-control {
    width: 22rem;
    border: 0;
    background-color: #fa5039;
    color: #fff;
    padding: 35px;
    border-radius: 50px 100px;
    margin-top: 40px;
    margin-left: 630px;
  }
}
.five-why-problem::-webkit-scrollbar,
.form-why-one::-webkit-scrollbar,
.form-why-two::-webkit-scrollbar,
.form-why-three::-webkit-scrollbar,
.form-why-four::-webkit-scrollbar,
.form-why-five::-webkit-scrollbar,
.five-why-cause::-webkit-scrollbar {
  width: 0px;
}
// .five-why-problem::-webkit-scrollbar-thumb {
// 	background: #fa4f39;
// }
// .five-why-problem::-webkit-scrollbar-thumb:hover {
// 	background: rgb(255, 0, 0);
// }
// h4.five-why-cause{
// 	width: 320px;
//     margin-top: 40px;
//     text-align: center;
// 	margin-left: 650px;
//     border: 1px solid;
//     padding: 25px;
//     border-radius: 50px 100px;
//     background-color: #fa5039;
//     color: #fff;
// }
//deming
.deming-parent {
  justify-content: center;
  margin-top: 30px;
}
.act-card {
  border-radius: 0 50px 0 60px;
  .act-row {
    display: flex;
    justify-content: flex-end;
  }
  .act-div {
    align-items: center;
    margin-top: 65px;
    margin-right: 10px;
  }
  textarea.deming-textarea-act.form-control {
    border-radius: 0 50px 0 0;
    height: 200px;
    background-color: rgba(240, 205, 9, 0.781);
    border: 0;
  }
}
.check-card {
  border-radius: 60px 0 50px 0;
  .check-row {
    display: flex;
    justify-content: flex-end;
  }
  .check-div {
    align-items: center;
    margin-top: 40px;
    margin-right: 10px;
  }
  textarea.deming-textarea-check.form-control {
    border-radius: 0 0 50px 0;
    height: 200px;
    background-color: rgb(75, 201, 30);
    border: 0;
  }
}
.plan-card {
  border-radius: 50px 0 60px 0;
  .plan-row {
    display: flex;
    // justify-content: flex-end;
  }
  .plan-div {
    align-items: center;
    margin-top: 50px;
    margin-right: 10px;
  }
  textarea.deming-textarea-plan.form-control {
    border-radius: 50px 0 0 0;
    height: 200px;
    background-color: #7d3bd3cc;
    border: 0;
  }
}
.do-card {
  border-radius: 0 60px 0 50px;
  .do-row {
    display: flex;
    // justify-content: flex-end;
  }
  .do-div {
    align-items: center;
    margin-top: 80px;
    margin-right: 10px;
  }
  textarea.deming-textarea-do.form-control {
    border-radius: 0 0 0 50px;
    height: 200px;
    background-color: #43ecd8;
    border: 0;
  }
}
.deming-textarea-act::-webkit-scrollbar,
.deming-textarea-plan::-webkit-scrollbar,
.deming-textarea-check::-webkit-scrollbar,
.deming-textarea-do::-webkit-scrollbar {
  width: 0px;
}

//eisenhower
.eisen-card-text {
  font-weight: 600;
  font-size: xx-large;
}
.eisenhower-img-div {
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 1;
  transform: translate(245%, -61%);
  // position: absolute ;
  // top: 50%;
  // left: 50%;
  // margin-left: 368px;
  // margin-top: -90px;
  // z-index: 1;
}
.eisen-do::-webkit-scrollbar,
.eisen-schedule::-webkit-scrollbar,
.eisen-delegate::-webkit-scrollbar,
.eisen-eliminate::-webkit-scrollbar {
  width: 0px;
}

//swot
.strength-parent,
.opportunities-parent {
  display: flex;
  align-items: flex-end;
  // display: flex;
  // align-items: flex-end;
  .swot-strength-img,
  .swot-opportunities-img {
    position: absolute;
    height: 550px;
    // position: absolute;
    // height: 550px;
    // position: absolute;
    // z-index: 0;
    // height: 550px;
  }
  .swot-strength-text,
  .swot-opportunities-text {
    margin-left: 25px;
    height: 180px;
    margin-bottom: 20px;
    z-index: 7;
    // border-color: #2CBDD1;
    // color: #2CBDD1;
    // margin-left: 20px;
    // margin-bottom: 20px;
    // height: 180px;
    // z-index: 7;
    // border: 0;
    // height: 170px;
    // display: flex;
    // align-items: flex-end;
    // margin-left: 20px;
    // width: 85%;
    // margin-bottom: 32px;
    // background-color: #0048ff;
  }
}
.weakness-parent,
.threats-parent {
  display: flex;
  align-items: flex-start;
  .swot-weakness-img {
    position: absolute;
    height: 550px;
    margin-top: -4px !important;
  }
  .swot-weakness-text {
    height: 180px;
    z-index: 7;
    margin-top: 15px;
    width: 90%;
    // border-color: #89D627;
    // color: #89D627;
  }
}
.swot-threats-text {
  margin-left: -205px;
  margin-top: 10px;
  width: 85%;
}
.trash-card-align {
  margin-top: -130px;
  margin-right: 10px;
}
.swot-strength-text::-webkit-scrollbar,
.swot-weakness-text::-webkit-scrollbar,
.swot-opportunities-text::-webkit-scrollbar,
.swot-threats-text::-webkit-scrollbar {
  width: 0px;
}
//rubberband
.rubberband-hold::-webkit-scrollbar,
.rubberband-pull::-webkit-scrollbar {
  width: 0px;
}

//Achuthan
.list-data {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}


.road-map-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
}
.day-picker {
  // background-color:green;
  color: white;
}
.date-text {
  text-align: right;
}
.button-text{
  text-align: center;
}
// responsive
@media(max-width:820px){
  .project-button{
    display: flex;
    flex-wrap: wrap;
    margin-top:2px;
    margin-left:auto;
    margin-right: auto;
    min-width: 50%;
    // background-color: #00ef6b;
  }
   
  .issue-group{
    display: flex;
    flex-wrap: wrap;
    margin-top:20px;
    margin-left:auto;
    margin-right:auto;
    min-width: 50%;
  }
  .add{
    width:43%;
  }
  .grid{
    width:43%;
  }
  

}

@media (max-width: 550px) {
  .ViewContainer h2 {
    text-align: center;
  }
  .project-button{
    // background-color: black;
    margin-top:5px;
  }
  .issue-group{
    margin-top:12px;
  }
  .road-map-container {
    display: block;
  }
  .Switch {
    width: 100%;
  }
  .day-picker {
    width: 100%;
    padding-top: 35px;
    padding-bottom: 35px;
  }
  .date-text {
    text-align: left;
    margin-bottom: 0px;
    display: inline-block;
  }
  .button {
    width: 100%;
  }
  .ViewContainer h2 {
    display: block;
  }
  .list-data {
    display: block;
    position:relative;
  }
  .list-dropdown{
    position:absolute;
    top:1px;
    right:3px;
  }
  .projectListCard .title-sec {
    // color:blue;
    width: 100%;
  }
  .list-data.description {
    display: block;
    text-align: center;
  }
  .cardHeader{
	position:relative;
  }
  .task-dropdown{
	position:absolute;
	top:5px;
	right:3px;
  }
  .task-tab-list{
	.taskHeader.card-header { 
		.title-element {
			display:block;
			width: 92%;;
			// align-items: center;
			.title-t-title {
        width:100%;
      }
			.font-weight-bolder,
			.sub-task-count-list,
			.card-data1-list,
			.card-data2-list {
			  width: 50%;
			  float: left;
			}
      .task-date {
        display: inline-block;
      }
      .sub-task-badge{
        // background-color: #004dff;
        padding-left:30px;
        position:absolute;
        top:15px;
        right:40px;
      }
		  }
      
	}

  }

//   .taskHeader.card-header{
// 	.title-element{
// 		display: block;
// 		width:92%;
// 	}
//   }
  // .projectListCard .items-count .badge{
  // 	width: 50%;
  //    }
  .projectListCard {
    .items-count {
      .badge:nth-child(1),
      .badge:nth-child(2) {
        width: 48%;
        margin-bottom: 10px;
      }
      .badge:nth-child(3),
      .badge:nth-child(4),
      .badge:nth-child(5) {
        width: 31.33%;
        padding-left: 1px;
        padding-right: 1px;
      }
    }
    .chart-dropdown {
      float: right;
    }
  }
}

div.popover.show.bs-popover-auto {
  max-width: 500px;
}

button.search-btn{
	display: flex;
    align-items: center;
    justify-content: center;
    max-height: 38px;
    max-width: 60px;
    margin-bottom: 6px;
	margin-left: -35px;
	border-radius: 0px 5px 5px 0px;
}

.projectGirdCard::after{content: ""; display: inline-block; width: 15px; height: 15px; position: absolute; right: 0; bottom: 0; background-color: #00EF6B}
.ReportCard .items-count { flex-wrap: wrap; }
.ReportListCard .items-count{ width: 74%; }
.ReportListCard .title-sec{ width: 21%; }
.ReportListCard .items-count .badge{ width: 18%; }
.ReportCard .items-count .badge{
	padding: 10px 15px;
	display: inline-block;
	font-weight: 500;
	border-radius: 3px;
}
//Chat post user css
.chat.post {
  .chat-avatar {
    float: right;
  }
  .chat-body {
    display: block;
    margin: 10px 30px 0 0;
    overflow: hidden;
    .chat-content {
      float: right;
      padding: 0.7rem 1rem;
      margin: 0 1rem 10px 0;
      clear: both;
      color: #ffffff;
      border-radius: 5px;
      box-shadow: 0 4px 8px 0 rgba(#000, 0.12);
      max-width: 75%;
      background: linear-gradient(118deg, #004dff, rgb(89 139 255 / 70%));
      // background-color: #ffffff;
      // border: 3px solid #0048ff;
      p {
        margin: 0;
      }
    }
  }
  
  // Chat area left css
  .chat-left {
    .chat-avatar {
      float: left;
    }
    .chat-body {
      .chat-content {
        float: left;
        margin: 0 0 10px 1rem;
        color: #0048ff;
        background: none;
        background-color: lighten(#7b92d1, 18%);
      }
    }
  }
}

//Chat post client css
.chat.notPost {
  .chat-avatar {
    float: left;
  }
  .chat-body {
    display: block;
    margin: 0 0 10px 30px ;
    overflow: hidden;
    .chat-content {
      float: left;
      padding: 0.7rem 1rem;
      margin: 0 0 10px 1rem;
      clear: both;
      color: #ffffff;
      border-radius: 5px;
      box-shadow: 0 4px 8px 0 rgba(#000, 0.12);
      max-width: 75%;
      background: linear-gradient(118deg, #004dff, rgb(89 139 255 / 70%));
      // background-color: #ffffff;
      // border: 3px solid #0048ff;
      p {
        margin: 0;
      }
    }
  }
  
  // Chat area left css
  .chat-left {
    .chat-avatar {
      float: left;
    }
    .chat-body {
      .chat-content {
        float: left;
        margin: 0 0 10px 1rem;
        color: #0048ff;
        background: none;
        background-color: lighten(#7b92d1, 18%);
      }
    }
  }
}

//Chat area css user
.chat.user {
  .chat-avatar {
    float: right;
  }
  .chat-body {
    display: block;
    margin: 10px 30px 0 0;
    overflow: hidden;
    .chat-content {
      float: right;
      padding: 0.7rem 1rem;
      margin: 0 1rem 10px 0;
      clear: both;
      color: #ffffff;
      border-radius: 5px;
      box-shadow: 0 4px 8px 0 rgba(#000, 0.12);
      max-width: 75%;
      background-color: #004DFF;
      p {
        margin: 0;
      }
    }
  }
  
  // Chat area left css
  .chat-left {
    .chat-avatar {
      float: left;
    }
    .chat-body {
      .chat-content {
        float: left;
        margin: 0 0 10px 1rem;
        color: #0048ff;
        background: none;
        background-color: lighten(#7b92d1, 18%);
      }
    }
  }
}

// Chat area css client
.chat.client {
  .chat-avatar {
    float: left;
  }
  .chat-body {
    display: block;
    margin: 0 0 10px 30px ;
    overflow: hidden;
    .chat-content {
      float: left;
      padding: 0.7rem 1rem;
      margin: 0 0 10px 1rem;
      clear: both;
      color: #5e5873;
      border-radius: 5px;
      box-shadow: 0 4px 8px 0 rgba(#000, 0.12);
      max-width: 75%;
      background-color: #ffffff;
      border: 1px solid #5e587325;
      p {
        margin: 0;
      }
    }
  }
  
  // Chat area left css
  .chat-left {
    .chat-avatar {
      float: left;
    }
    .chat-body {
      .chat-content {
        float: left;
        margin: 0 0 10px 1rem;
        color: #0048ff;
        background: none;
        background-color: lighten(#7b92d1, 18%);
      }
    }
  }
}
// //popup style
// div.popover.show.bs-popover-auto {
//   max-width: 50% !important;
// }
//table style report project list
table.projectlist-table.table {
  border-bottom-left-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}
table th, table td {
  padding-top: 10px !important;
  padding-bottom: 10px !important;
}
th.text-center.th-date, th.text-center.th-task, th.text-center.th-issue, th.text-center.th-time, th.text-center.th-status {
  max-width: 200px !important;
  overflow: hidden;
}
td.text-center.td-date, td.text-center.td-task, td.text-center.td-issue, td.text-center.td-time, td.text-center.td-status {
  max-width: 200px !important;
  overflow: hidden;
}
//client table
table.client-table.table {
  border-bottom-left-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

//page height
div.app-content.content.overflow-hidden {
  min-height: 90vh
}
#react-select {
.select__menu {
  z-index: 9 !important;
}
}